<template>
	<div class="home">

		<v-card>
			<v-container fluid>
				<v-row><v-col cols="12"></v-col></v-row>

                <v-row>
                    <v-col cols="12">
                        <v-tabs >
                            <v-tab v-on:click="changeView(1)" v-if="loadRawHistory == 1">Raw History</v-tab>
                            <v-tab v-on:click="changeView(2)" v-if="loadTransaction == 1">Transaction</v-tab>
                            <v-tab v-on:click="changeView(3)" v-if="loadProfitLoss == 1">Profit & Loss</v-tab>
                            <v-tab v-on:click="changeView(4)" v-if="loadVolume == 1">Volume</v-tab>
                        </v-tabs>
                    </v-col>
                </v-row>

                <OrderSearch v-if="selectedModule == 1" />
                <TransactionSearch v-if="selectedModule == 2" />
                <ProfitLossSearch v-if="selectedModule == 3" />
                <VolumeSearch v-if="selectedModule == 4" />

            </v-container>
        </v-card>
    </div>
</template>
<script>
import VolumeSearch from "./component/ComponentVolumeSearch";
import OrderSearch from "./component/ComponentOrderSearch";
import TransactionSearch from "./component/ComponentTransactionSearch";
import ProfitLossSearch from "./component/ComponentProfitLossSearch";
import * as apiBook from "@components/api/pk/book";
import * as apiGroup from "@components/api/pk/group";
import * as apiSymbol from "@components/api/pk/symbol";
import * as apiTrade from "@components/api/pk/trades";
import DataTable from "@components/search/DataTable";
import { permission } from "@components/mixins/permission";
import moment from "moment";
import Breadcrumbs from "@components/Breadcrumbs";
import { mapActions, mapState } from "vuex";
moment.locale("en-au");

export default {
    components: {
        DataTable,
        Breadcrumbs,
        OrderSearch,
        TransactionSearch,
        ProfitLossSearch,
        VolumeSearch
    },
    mixins: [permission],
    data () {
        return {
            selectedModule: 1,
            loadRawHistory: 0,
            loadTransaction: 0,
            loadProfitLoss: 0,
            loadVolume: 0
        };
    },
    methods: {
        changeView (key) {
            this.selectedModule = key;
        }
    },
    mounted () {
        const userFunction = this.getFunction("search");
        console.log(userFunction);

        if (userFunction.includes("rawHistory")) {
            this.loadRawHistory = 1;
        }
        if (userFunction.includes("transaction")) {
            this.loadTransaction = 1;
        }
        if (userFunction.includes("profitLoss")) {
            this.loadProfitLoss = 1;
        }
        if (userFunction.includes("volume")) {
            this.loadVolume = 1;
        }
    }
};
</script>
